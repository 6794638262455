.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white; /* Set text color to white */
}

h1 {  /* Styles for the h1 tag */
  color: white; /* This makes sure your 'Hello World' text is white */
  font-size: 2rem; /* Adjust font size as needed */
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5); /* Optional: Adds text shadow for better readability */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
